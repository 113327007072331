<template>
  <v-container>
    <BaseScreenHeader
      title="Muscle Search"
      screenInfo="SRC-001"
      width="90%"
      :showBack="true"
      :refresh="false"
      :showNav="true"
      @refresh="refresh"
    />
    <BaseFreeTier v-if="loaded && loadedList.length > 5" />
    <BaseLoading :loaded="loaded" label="Loading" />
    <v-sheet v-if="articlesList.length > 0" class="charcoal">
      <v-card
        rounded
        class="pa-2 charcoal mt-2 rounded-lg rounded-t-0 charcoalTileMenu"
        v-for="item in articlesList"
        :key="item.measurementid"
      >
        <v-card-text :class="textSize + ' pa-0 paper--text'">
          {{ item.external_name }}
        </v-card-text>
        <v-card-actions :class="textSizeSmall + ' pa-0'">
          <v-spacer />

          <BaseTag
            icon=""
            :label="capitalize(item.result_type)"
            tagsize="medium"
            color="transparent charcoalTileMenu pinkAccent--text"
          />
        </v-card-actions>
        <v-container
          fluid
          fill-height
          :class="
            item.result_type != 'article'
              ? 'paper pa-0 charcoalTileMenu'
              : 'pa-0 charcoalTileMenu'
          "
        >
          <v-layout align-center justify-center>
            <v-flex xs12 md8>
              <v-img
                :src="item.media"
                contain
                max-width="100%"
                max-height="100%"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-text :class="textSizeSmall + ' paper--text pa-0 mt-3'">
          <span v-html="item.external_details" :class="textSizeXSmall"> </span>
        </v-card-text>
        <v-card flat class="transparent pa-2">
          <v-card-actions
            v-if="item.result_type != 'muscle'"
            :class="textSizeXSmall + ' px-0 py-2'"
          >
            <v-spacer />
            <v-btn color="paper" class="" tonal @click="openContent(item)">
              VIEW {{ capitalize(item.result_type) }}
              <v-icon>
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-sheet>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'


export default {
  components: {},
  name: 'SearchByMuscle',
  mixins: [validationMixin, util],
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      switchNum: 0,
      loaded: false,
      isActive: false,
      showAddArticle: false,
      showDeleteDialog: false,
      showHelpText: false,
      isUserScrolling: false,
      isNow: true,
      selectedArticle: {},
      metric: 0,
      imperial: 0,
      feet: 0,
      inches: 0,
      measured: '',
      search: '',
      articlesList: [],
      loadedList: [],
      datetime: null,
      selectedDateTime: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false,
      reload: false
    }
  },
  props: {
    muscleid: {
      type: String
    }
  },
  beforeMount() {
    this.loadPage()
  },
  mounted() {
  },
  beforeDestroy() {

  },

  watch: {
  },
  computed: {
    itemsCount() {
      return this.articlesList.length
    },

    ...appConfig
  },
  methods: {
    goTo(path) {
      if (this.$route.path != path) {
        this.$router.push(path)
      } else this.$router.go()
    },
    openContent(item) {
      if (item.result_type == 'exercise') {
        this.goTo('/exercises/view/'+item.externalid)
      } else {
        this.goTo('/articles/view/'+item.externalid)
      }
    },
    setResults(value) {
      this.articlesList = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    doneAdding() {
      this.showAddArticle = false
      this.currentPage = 0
      this.reload = true
      this.loadPage()
    },

    showRowDialog(value) {
      this.selectedArticle = value
      this.showDeleteDialog = true
    },
    refresh() {
      this.reload = true
      this.currentPage = 0
      this.loadPage()
    },
    loadPage() {
      this.loaded = false
      return axios
        .get(this.baseURL + '/search/bymuscle/' + this.muscleid, {})
        .then(response => {
          if (response.status == 200) {
            response.data.data.forEach(element => {
              element.showFullContent = false
            })

            if (this.reload) this.articlesList = response.data.data
            else
              this.articlesList = this.articlesList.concat(response.data.data)

            this.loadedList = this.articlesList.slice()
            this.loaded = true
            this.reload = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    searchArticles() {
      {
        this.reload = true
        this.loadPage()
      }
    }
  }
}
</script>

<style>
/* Add CSS transitions for fading effect */
/* Add CSS transitions for fading effect */
.excerpt {
  background: linear-gradient(transparent 0%, rgba(255, 255, 255, 1) 70%);
  -webkit-background-clip: text;
  background-clip: text;
  color: #efefef;
  display: inline;
  transition: background 0.5s ease-in-out;
  cursor: pointer;
}

.excerpt:hover {
  background: linear-gradient(transparent 0%, rgba(255, 255, 255, 1) 0%);
  color: #fafafa; /* Set the text color when hovered */
}
</style>
